<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="日期：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="发布地区：">
        <cascader-area
          :country="queryInfo.releaseAddressCountry"
          :province="queryInfo.releaseAddressProvince"
          :city="queryInfo.releaseAddressRegion"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="招聘类别：">
        <dic-radio-button
          :code.sync="queryInfo.jobType"
          type-code="033"
          query-name="jobType"
          name="招聘类别"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.activityIndustryName"
          query-name="activityIndustryName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.activityFieldName"
          query-name="activityFieldName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-tag-button
          :code.sync="queryInfo.activityTechName"
          query-name="activityTechName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="招聘状态：">
        <dic-radio-button
          :code.sync="queryInfo.jobStatus"
          type-code="035"
          query-name="jobStatus"
          name="招聘状态"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >
          <import-data moduleName="招聘数据" @fileClick="fileClick" />
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="根据招聘职位、企业名称、职位介绍进行搜索"
          v-model="queryInfo.jobTitle"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataCompletion"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.dataCompletion"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="jobTitle" label="招聘职位" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.jobTitle }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称" align="center">
          <template v-slot="{ row }">
            <div>
              <span v-if="row.companyName">{{ row.companyName }}</span>
              <span v-else>{{ row.companyCode }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="technologyType" label="技术类别" align="center">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.technologyType"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.technologyType !== null &&
                      scope.row.technologyType !== ''
                    "
                  >
                    {{ scope.row.technologyType.substring(0, 20) }}
                    <span v-if="scope.row.technologyType.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="neighborhoodType"
          label="领域类别"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.neighborhoodType"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.neighborhoodType !== null &&
                      scope.row.neighborhoodType !== ''
                    "
                  >
                    {{ scope.row.neighborhoodType.substring(0, 20) }}
                    <span v-if="scope.row.neighborhoodType.length > 20"
                      >...</span
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="workExperience"
          label="工作经验"
          align="center"
        />
        <el-table-column
          prop="releaseAddress"
          label="发布地址"
          align="center"
        />
        <el-table-column prop="jobDescription" label="职位介绍" align="center">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.jobDescription"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.jobDescription !== null &&
                      scope.row.jobDescription !== ''
                    "
                  >
                    {{ scope.row.jobDescription.substring(0, 20) }}
                    <span v-if="scope.row.jobDescription.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" align="center" />
        <el-table-column
          prop="uploader"
          label="创建人"
          align="center"
          width="80"
        />
        <el-table-column
          prop="uploadTime"
          label="创建时间"
          align="center"
          width="100"
          :formatter="formatTime"
        />
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";
import CascaderArea from "@/components/CascaderArea.vue";
// import DataTime from '@/components/DataTime.vue'
import ImportData from "@/components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "../Product/com/dataTime.vue";
import { pagination } from "@/mixins/pagination";
import { getActivity } from "@/api/activity";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { listRecruitmentPage, deleteRecruitment } from "@/api/recruit";
import { exportData } from "../../utils/tool";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    CascaderArea,
    Popover,
    DicCheckboxGroup,
  },

  data() {
    return {
      // 查询
      queryInfo: {
        columnName: "update_time",
        // columnNames:["update_time"],
        pageSize: 10,
        pageNum: 1,
        jobTitle: "", // 搜索查询条件
        jobType: "", //活动类别
        jobStatus: "", //费用类别
        years: null, // 年
        month: null, // 月
        day: null, // 日
        id: [],
        // projectCountry: '', // 国家
        // projectProvince: '', // 省份
        // projectCity: '', // 城市
        // projectStage: '', // 项目阶段
        // reportTags: [], // 行业类别
        // reportTagsName: '', // 行业辅助标签
        // fieldId: [], // 领域类别
        activityField: [],
        // fieldIdName: '', // 领域辅助标签
        order: 1, // 0正序 1倒叙
        // size: '',
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: "招聘类别",
          tagName: "jobType",
        },
        {
          name: "行业类别",
          tagName: "activityIndustryName",
        },
        {
          name: "领域类别",
          tagName: "activityFieldName",
          valLength: 0,
        },
        {
          name: "技术类别",
          tagName: "activityTechName",
          valLength: 0,
        },
        {
          name: "招聘状态",
          tagName: "jobStatus",
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    };
  },
  created() {
    this.search();
  },

  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      this.pageNum = 1;
      const res = await listRecruitmentPage(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.total = res.data.total;
        this.projectTable.forEach((el) => {
          if (el.dataCompletion) {
            el.dataCompletion = Number(el.dataCompletion);
          } else {
            el.dataCompletion = 0;
          }
        });
      }

      this.total = res.data.total;
    },
    searchCountry(val) {
      this.queryInfo.releaseAddressCountry = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.releaseAddressProvince = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.releaseAddressRegion = val;
        this.search();
      }
    },
    updateCode(val, tag) {
      // this.nextTick(() => {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
      // })
    },
    async uploadTime() {
      this.queryInfo.pageNum = 1;
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "change_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.releaseTimeYear = "";
        this.queryInfo.releaseTimeMonth = "";
        this.queryInfo.releaseTimeDay = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.releaseTimeYear = obj.year + "";
        this.queryInfo.releaseTimeMonth = "";
        this.queryInfo.releaseTimeDay = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.releaseTimeYear = obj.year + "";
        this.queryInfo.releaseTimeMonth = obj.month + "";
        this.queryInfo.releaseTimeDay = "";
        this.search();
      } else {
        this.queryInfo.releaseTimeYear = obj.year + "";
        this.queryInfo.releaseTimeMonth = obj.month + "";
        this.queryInfo.releaseTimeDay = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      this.queryInfo.pageNum = 1;
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "行业类别":
          this.queryInfo.industryType = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryType =
              this.queryInfo.industryType.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.neighborhoodTypes = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.neighborhoodTypes =
              this.queryInfo.neighborhoodTypes.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }
          break;

        case "技术类别":
          this.queryInfo.technologyType = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.technologyType =
              this.queryInfo.technologyType.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }

      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "activityIndustryName":
          this.queryInfo.activityIndustryName = "";
          this.queryInfo.industryType = [];
          break;
        case "activityTechName":
          this.queryInfo.activityTechName = "";
          this.queryInfo.technologyType = [];
          break;
        case "activityFieldName":
          this.queryInfo.activityFieldName = "";
          this.queryInfo.neighborhoodTypes = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    editItem(row) {
      let text = this.$router.resolve({
        path: "/recruit/recruitInfo",
        query: {
          projectDataType: 1,
          id: row.id,
        },
      });
      window.open(text.href, "_blank");
    },

    addItem() {
      this.$router.push({
        name: "RecruitInfo",
        query: {
          projectDataType: 0,
        },
      });
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;

      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }

      this.search();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 正序
    caretTop() {
      // this.queryInfo.order = 0;
      // this.search();
    },
    // 倒序
    caretBottom() {
      // this.queryInfo.order = 1;
      // this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.id = val.map((item) => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteRecruitment(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);

      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length) {
        this._deleteProject(this.delete.multiId);
      }
    },
    sortChange(column) {
      this.queryInfo.pageNum = 1;
      if (column.prop === "dataCompletion") {
        this.queryInfo.columnName = "project_data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "change_time";
        }
      }
      this.search();
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { companyCode: [], size: "" });
      } else if (val == 0 && this.queryInfo.id.length) {
        Object.assign(this.queryInfo, {
          id: this.queryInfo.id,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/recruitment/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "招聘数据.xls"
      );

      // this.queryInfo.id = []
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "行业类别") {
        return "cursor:pointer";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
